<template>
  <div class="loanDetail">
    <mt-header :title="$t('loanDetail')">
      <router-link v-if="callBack" :to="callBack" slot="left">
        <mt-button icon="back">{{ $t('back') }}</mt-button>
      </router-link>
      <router-link v-else to slot="left">
        <mt-button @click="$router.back(-1)" icon="back">{{ $t('back') }}</mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <div  class="loan_header" >
      <div class="loan_header_container">
        <div class="loan_header_title">Order status</div>
        <div class="st_item">
          <div class="st_item_status">{{ item.contractStatusName}}</div>
          <div class="st_item_days" v-if="item.contractStatus === 'OVERDUE'|| item.contractStatus === 'NORMAL'">{{ item.overDueDay}} Days</div>
        </div>
        <div v-if="item.contractStatus === 'OVERDUE' || item.contractStatus === 'NORMAL'">
          <div class="nap"></div>
          <div class="loan_header_title">Total Repayment Amount</div>
          <div class="loan_header_amount"> ₦ {{ item.totalRepayAmount | formatMoney}}</div>
          <div class="date_item">
            <div class="item" style="text-align: left">
              <div>Start Date</div>
              <div class="item_date">{{item.applyDate}}</div>
            </div>
            <div class="item">
              <div>——</div>
            </div>
            <div class="item" style="text-align: right">
              <div>Due date</div>
              <div class="item_date">{{item.lastTermPmtDueDate}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="container">
        <mt-cell title="Principal">
          <div> ₦ {{item.principalAmount | formatMoney  }}</div>
        </mt-cell>
        <mt-cell title="Interest">
          <div>₦ {{item.interest | formatMoney  }}</div>
        </mt-cell>
        <mt-cell title="Service charge">
          <div>₦ {{item.serviceFees | formatMoney  }}</div>
        </mt-cell>
        <mt-cell title="Repayment Charge">
          <div>₦ {{item.totalMgtFee | formatMoney  }}</div>
        </mt-cell>
        <mt-cell v-if="item.contractStatus === 'OVERDUE'" title="Penalty charge">
          <div>₦ {{item.penaltyAmount | formatMoney }}</div>
        </mt-cell>
      </div>
    </div>
    <div class="content">
      <div class="container">
        <mt-cell title="Contract ID">
          <div>{{ contractNo }}</div>
        </mt-cell>
        <mt-cell title="Product type">
          <div>{{ item.loanProductDesc}}</div>
        </mt-cell>
      </div>
    </div>
    <div v-if="item.contractStatus === 'OVERDUE' || item.contractStatus === 'NORMAL'" class="bottom">
      <div class="foot_amount">₦ {{ item.totalRepayAmount}}</div>
      <div class="foot_button">
        <mt-button type="primary" size="large" @click="$router.push(`/apply/repayment?contractNo=${contractNo}`)">Repay Now</mt-button>
      </div>
    </div>
  </div>
</template>

<script>
import callPhone from '@/components/callPhone.vue'
import { contractDetail } from '../../utils/api'
export default {
  name: 'LoanDetail',
  components: {
    callPhone
  },
  computed: {
    contractNo() {
      return this.$route.query.contractNo
    },
    callBack() {
      return this.$route.query.callBack
    }
  },
  data () {
    return {
      item: {}
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      await this.getContractDetail()
      this.$indicator.close();
    },
    async getContractDetail() {
      await this.$axios({
        method: 'post',
        url: contractDetail,
        params: {
          applyId: this.contractNo
        }
      })
        .then((e) => {
          if (e.status.code === '000') {
            this.item = e.body
            console.log(this.item)
          }
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.loanDetail {
  min-height: 100vh;
  background-image: linear-gradient(to right, #47B526 , #5cd239);
  background-size: cover;
  .detail {
    width: 16px;
    margin: 0 5px;
  }
  .mint-cell {
    background: rgba(255, 255, 255, 0.8);
    &:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    &:first-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    min-height: 36px;
  }
  .loan_header{
    background-color: #FFFFFF;
    margin: 20px 25px 20px 25px;
    border-radius: 20px;
    min-height: 80px;
    text-align: center;
    .loan_header_container{
      width: 80%;
      margin: 0 auto;
      text-align: left;
    }
    .loan_header_title{
      padding-top: 20px;
    }
    .loan_header_amount{
      font-size: 32px;
      font-weight: 800;
    }
    .nap{
      margin-top: 8px;
      border-top: 2px dashed #777171;
    }
    .st_item{
      display: flex;
      flex-grow: 2;
      margin-top: 5px;
      font-size: 20px;
      font-weight: 600;
      .st_item_status{
        float: left;
        width: 50%;

      }
      .st_item_days{
        text-align: right;
        float: right;
        width: 50%;
      }
    }
    .date_item{
      display: flex;
      text-align: center;
      margin-top: 10px;
      padding-bottom: 10px;
      .item{
        flex-grow: 3;
      }
      .item_date{
        margin-top: 5px;
        font-weight: 600;
      }
    }
  }
  .content {
    background-color: #FFFFFF;
    margin: 20px 25px 20px 25px;
    border-radius: 20px;
    .container{
      width: 80%;
      margin: 0 auto;
      text-align: left;
    }
  }
  .stages {
    .item {
      box-shadow: 0px 0px 2px 0px rgba(34, 35, 40, 0.22);
      margin: 10px 20px;
      padding: 10px;
      border-radius: 5px;
      .title {
        font-weight: bold;
        padding-bottom: 10px;
        font-size: 18px;
      }
      /deep/ .mint-cell-value {
        color: brown;
        font-weight: 600;
      }
      .free {
        padding: 5px 10px;
        background: #F15234;
        color: white;
        border-radius: 5px;
        margin-left: 5px;
      }
    }
  }
  .bottom {
    padding: 20px 20px 20px 20px;
    display: flex;
    flex-grow: 2;
    margin: 20px 25px 20px 25px;
    border-radius: 20px;
    background-color: #FFFFFF;
    .foot_amount{
      width: 50%;
      font-size: 20px;
      line-height: 40px;
    }
    .foot_button{
      display: flex;
      width: 50%;
    }
  }
}
</style>
